/* eslint-disable @typescript-eslint/no-explicit-any */
import { OrganizationContext, PRODUCT_PLANS } from '@diagrid/cloud-ui-shared';
import { isNil } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UsersRolesContext } from 'src/contexts/UsersRolesContext';

declare global {
  interface Window {
    hsConversationsOnReady?: any;
    HubSpotConversations?: any;
    hsConversationsSettings: any;
    _hsq: any;
    hbspt: any;
  }
}

// HUBSPOT CHAT BOT INTEGRATION
// eslint-disable-next-line vars-on-top, no-var, no-unused-vars, no-multi-assign, @typescript-eslint/naming-convention
var _hsq = (window._hsq = window._hsq || []);
// window.hsConversationsSettings = {
//   loadImmediately: false,
// };

const {
  mcp: { free },
} = PRODUCT_PLANS;

export const useHubspotChat = (portalId) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hsConvoInited, setHsConvoInited] = useState(false);
  const { diagridAccount } = useContext(UsersRolesContext);
  const { planName, loading: loadingOrg } = useContext(OrganizationContext);

  const isFreePlan = planName === free;

  const currentUser = useMemo(() => diagridAccount ?? {}, [diagridAccount]);

  useEffect(() => {
    let script;

    if (!isFreePlan && planName !== null && !loadingOrg) {
      if (window.HubSpotConversations) {
        setHasLoaded(true);
      } else {
        // TODO this doesn't seem to be getting called, check on window.HubSpotConversations instead.
        window.hsConversationsOnReady = [
          () => {
            setHasLoaded(true);
          },
        ];
      }

      // check the appended script tag to see if it exists already
      const exists = document.querySelector(`script[src="https://js.hs-scripts.com/${portalId}.js"]`);

      if (!exists) {
        // Create script component.
        script = document.createElement('script');
        script.src = `https://js.hs-scripts.com/${portalId}.js`;
        script.async = true;

        document.body.appendChild(script);
      }
    }
    // Add event listener.

    return () => {
      if (!isFreePlan && planName !== null && !loadingOrg && script) {
        document.body.removeChild(script);
        window.hsConversationsOnReady = [];
      }
    };
  }, [isFreePlan, loadingOrg, planName, portalId]);

  useEffect(() => {
    if (!isFreePlan && planName !== null && !loadingOrg) {
      if (window.HubSpotConversations && !hsConvoInited) {
        setHsConvoInited(true);
        if (isNil(currentUser?.hsToken)) {
          // somethings up with the hubspot user id api.
          // we should still load the widget, but not identify the user
          window.HubSpotConversations?.widget?.load();
        } else {
          // if we don't identify the user, track the page view and clear/reset the widget the contact will not be created in hubspot and the new chat will show unknown user
          // eslint-disable-next-line no-undef
          _hsq.push([
            'identify',
            {
              email: currentUser.email,
              name: currentUser.name,
              nickname: currentUser.nickname,
            },
          ]);
          // Track the page view for the new page
          // eslint-disable-next-line no-undef
          _hsq.push(['trackPageView']);

          window.hsConversationsSettings = {
            identificationEmail: currentUser.email,
            identificationToken: currentUser.hsToken,
          };

          try {
            const status = window.HubSpotConversations.widget.status();

            if (status.loaded) {
              window.HubSpotConversations.clear({ resetWidget: true });
            } else {
              window.HubSpotConversations.widget.load();
            }
          } catch (e) {
            console.warn(e);
          }
        }
      }
    }
  }, [currentUser, hsConvoInited, isFreePlan, loadingOrg, planName]);

  const openHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close();
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    openHandler,
    closeHandler,
  };
};
