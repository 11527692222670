import { IconButtonAnimate, MenuPopover, Scrollbar } from '@diagrid/cloud-ui-shared/components';
import { useLocalStorage, useWindowFocus } from '@diagrid/cloud-ui-shared/hooks';
import { getDateFromUTC } from '@diagrid/cloud-ui-shared/utils';
import { AccessTimeSharp, MarkEmailUnread, NotificationsActiveSharp } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { isNil, truncate } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useLocales } from 'src/hooks/useLocales';
import { useFindQuery } from 'src/redux/store';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { TIMEOUTS } from 'src/utils/constants';
import { fTimeSince } from 'src/utils/formatTime';

export function NotificationsPopover() {
  const { translate } = useLocales();
  const { conditionalPolling } = useWindowFocus();
  const [notifications, setNotifications] = useState([]);
  const [open, setOpen] = useState(null);

  const { data } = useFindQuery(
    { type: 'diagridNotifications', data: { params: { pagingMethod: 'server', pagingState: {} } }, normalization: { skip: true } },
    { pollingInterval: conditionalPolling(TIMEOUTS.POLL_INTERVAL_5m) }
  );
  const [latestNotificationCookie, setLatestNotificationCookie] = useLocalStorage('latestNotification', null);
  const totalNotifications = data?.meta?.count || 0;

  const totalUnRead = useMemo(() => {
    if (isNil(data)) {
      return 0;
    }
    const latestNotificationTime = data?.data?.[0]?.updatedAt;
    const latestCookieTime = latestNotificationCookie?.updatedAt;

    if (isNil(latestCookieTime)) {
      return data?.meta?.count || 0;
    }

    if (latestCookieTime && latestNotificationTime) {
      return data.data.filter((n) => n.updatedAt > latestCookieTime).length;
    }

    return data?.meta?.count || 0;
  }, [data, latestNotificationCookie?.updatedAt]);

  useEffect(() => {
    if (data?.data.length > 0) {
      setNotifications(data.data);
    }
  }, [data]);

  useEffect(() => {
    // only set latestNotification cookie if we've opened the popover, essentially marking the notifications as read
    if (notifications.length > 0 && !isNil(open)) {
      const latest = notifications[0];
      if (latestNotificationCookie === null || latestNotificationCookie?.id !== latest.id) {
        setLatestNotificationCookie(latest);
      }
    }
  }, [latestNotificationCookie, notifications, open, setLatestNotificationCookie]);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge max={5} badgeContent={totalUnRead} color="error">
          <NotificationsActiveSharp sx={{ height: 20, width: 20 }} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose} sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {translate('notifications.popover.total', { count: totalNotifications })}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                {translate('generalLabels.recent')}
              </ListSubheader>
            }
          >
            {notifications.slice(0, 5).map(({ id, displayText, updatedAt }) => (
              <NotificationItem key={id} id={id} displayText={displayText} updatedAt={updatedAt} onClick={handleClose} />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple to={PATH_DASHBOARD.notifications.events} component={RouterLink}>
            {translate('generalLabels.viewAll')}
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

type NotificationItemProps = {
  id: string;
  displayText: string;
  updatedAt: string;
  onClick?: () => void;
};

function NotificationItem({ id, displayText, updatedAt, onClick }: NotificationItemProps) {
  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        alignItems: 'flex-start',
      }}
      to={`${PATH_DASHBOARD.notifications.events}?id=${id}`}
      component={RouterLink}
      onClick={onClick}
    >
      <ListItemAvatar sx={{ mt: 0.5 }}>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>
          <MarkEmailUnread />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<Typography variant="subtitle2">{truncate(displayText, { length: 76 })}</Typography>}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <AccessTimeSharp sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fTimeSince(getDateFromUTC(updatedAt))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
