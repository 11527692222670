import { TextField, TextFieldProps } from '@mui/material';
import { isEmpty } from 'lodash';
import { PropsWithChildren } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type RHFSelectProps = {
  name: string;
  native?: boolean;
  multiple?: boolean;
} & TextFieldProps;

export function RHFSelect({ name, children, native = true, ...other }: PropsWithChildren<RHFSelectProps>) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{
            native,
            multiple: other?.multiple ?? false,
            SelectDisplayProps: {
              'data-cy': name,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
          }}
          error={!!error}
          helperText={error?.message ?? other?.helperText}
          InputLabelProps={{ shrink: isEmpty(field.value) || (!isEmpty(field.value) && other?.SelectProps?.displayEmpty) }} // this is so stupid, but it works. basically if the default value is an empty array or the type is multiple and the value isn't empty then shrink the label. Otherise the label will overlap the empty value if displayEmpty
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
