import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { deserialise, serialise } from 'kitsu-core';
import { API_METHODS } from '../../utils/globals';
import { ErrorResponseType } from '../types';

type HandleDeleteRequestOptions = {
  axiosOptions?: Partial<AxiosRequestConfig>;
  jsonApiClient: AxiosInstance;
  params?: Record<string, unknown>;
  resourceId: string | string[];
  resourceType: string;
  url: string;
};

// This function handles Delete requests and normalizes the response data
export async function handleDelete({
  jsonApiClient,
  url,
  params,
  axiosOptions = {},
  resourceId,
  resourceType,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
HandleDeleteRequestOptions): Promise<QueryReturnValue<{ data: any } | { error: any }, any, any>> {
  const isBulk = Array.isArray(resourceId);
  // TODO - Bulk delete is not yet implemented in API
  const path = isBulk ? url : `${url}/${encodeURIComponent(resourceId)}`;
  const payload = isBulk ? resourceId.map((id) => ({ id })) : { id: resourceId };

  // Serialise the payload
  const serialData = serialise(resourceType, payload, API_METHODS.DELETE);

  // Try to delete the resource
  try {
    const { data: responseData } = await jsonApiClient.delete(path, {
      data: serialData,
      params,
      ...axiosOptions,
    });

    // Try to deserialise the response data and return unmodified if it fails
    try {
      return { data: deserialise(responseData) ?? {} };
    } catch (error) {
      console.warn('Error deserialising resource', error);
      return { data: { ...responseData } };
    }
  } catch (error) {
    const err = error as ErrorResponseType;
    const status = err.response?.status;
    const data = err.response?.data?.errors ?? err.response?.data ?? err.message;
    return {
      error: {
        status,
        data,
      },
    };
  }
}
