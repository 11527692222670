import { getValForEmptySelect } from '@diagrid/cloud-ui-shared';
import { Autocomplete, Chip, createFilterOptions, FormControl, TextField, TextFieldProps } from '@mui/material';
import { find, isArray, isEmpty, map, omit, reduce } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

const filter = createFilterOptions();

type RHFAutoCompleteSelectProps = {
  name: string;
  options: Record<string, unknown>[];
  multiple?: boolean;
  valueIsLabel?: boolean;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  autoCompleteOptions?: any;
} & TextFieldProps;

export const RHFAutoCompleteSelect = ({
  name,
  options,
  multiple = false,
  valueIsLabel = true,
  required,
  autoCompleteOptions = {},
  ...rest
}: RHFAutoCompleteSelectProps) => {
  const formContext = useFormContext();

  const other = omit(rest, 'autoCompleteOptions');

  if (autoCompleteOptions?.freeSolo) {
    autoCompleteOptions.filterOptions = filterOptions;
  }

  return (
    <Controller
      name={name}
      control={formContext.control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          autoSelect
          disableListWrap
          options={options}
          multiple={multiple}
          isOptionEqualToValue={(option: { inputValue: string; value: string; label: string }, value) =>
            (option?.inputValue ?? option?.value ?? option) === value
          }
          value={getValForEmptySelect(field.value, multiple)}
          onChange={(_, newValue) => {
            const valueToOnchange = multiple
              ? map(
                  newValue,
                  (option: { inputValue: string; value: string; label: string }) => option?.inputValue ?? option?.value ?? option
                )
              : (newValue?.inputValue ?? newValue?.value ?? newValue);
            return field.onChange(valueToOnchange);
          }}
          getOptionLabel={(option) => {
            // when we're not using the value as the label and teh value is a string (meaning its coming from the input)
            // show the correct label in the input
            if (!valueIsLabel && typeof option === 'string') {
              return option;
            }

            if (option?.inputValue) {
              return option.inputValue;
            }

            return option?.label ?? option;
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              if (option?.inputValue) {
                return (
                  <Chip
                    key={option?.inputValue ?? option}
                    {...getTagProps({ index })}
                    size="medium"
                    label={<>option?.inputValue ?? option</>}
                  />
                );
              }

              // TODO - I think there is a bug here
              const label = valueIsLabel ? (option?.label ?? option) : (find(options, { value: option })?.label as string);
              return <Chip key={option.label ?? option} {...getTagProps({ index })} size="medium" label={<>{label}</>} />;
            })
          }
          renderInput={(params) => {
            let err = error;
            if (err && isArray(err)) {
              err = {
                ...error,
                message: reduce(
                  err,
                  (acc, e) => {
                    // didn't make this a single line because it was hard to reason about
                    // we have to check for a undefined because this could the select could support multiple values
                    // and the errors are positional
                    // e.g. if [0] is undefined, then [1] is the error for the second value
                    if (isEmpty(acc)) {
                      return e?.message ?? '';
                    }
                    return `${acc},\n${e.message}`;
                  },
                  ''
                ),
              };
            }
            return (
              <FormControl fullWidth required={required}>
                <TextField
                  fullWidth
                  error={!!err}
                  helperText={err?.message}
                  {...params}
                  {...other}
                  required={required && field?.value?.length === 0}
                />
              </FormControl>
            );
          }}
          {...autoCompleteOptions}
        />
      )}
    />
  );
};

function filterOptions(options, params) {
  const filtered = filter(options, params);

  const { inputValue } = params;
  // Suggest the creation of a new value
  const isExisting = options.some((option) => inputValue === (option?.label ?? option?.name ?? option?.title ?? option?.id));
  if (inputValue !== '' && !isExisting) {
    filtered.push({
      inputValue,
      title: `${inputValue}`,
    });
  }

  return filtered;
}
