import { authz } from '@diagrid/shared-js';
import {
  CallToActionSharp,
  CorporateFareRounded,
  DashboardSharp,
  DeveloperBoardSharp,
  GroupSharp,
  KeySharp,
  NotificationsSharp,
  StoreSharp,
  SupportAgentSharp,
  AppRegistrationSharp,
} from '@mui/icons-material';
import { values } from 'lodash';
import { NavConfig } from 'src/types/Navigation';
import { PATH_DASHBOARD } from '../../../routes/paths';

const ICONS = {
  apiKeys: <KeySharp sx={{ width: 1, height: 1 }} />,
  user: <GroupSharp sx={{ width: 1, height: 1 }} />,
  dashboard: <DashboardSharp sx={{ width: 1, height: 1 }} />,
  clusters: <DeveloperBoardSharp sx={{ width: 1, height: 1 }} />,
  alerts: <CallToActionSharp sx={{ width: 1, height: 1 }} />,
  notifications: <NotificationsSharp sx={{ width: 1, height: 1 }} />,
  organization: <CorporateFareRounded sx={{ width: 1, height: 1 }} />,
  sales: <StoreSharp sx={{ width: 1, height: 1 }} />,
  support: <SupportAgentSharp sx={{ width: 1, height: 1 }} />,
  componentBuilder: <AppRegistrationSharp sx={{ width: 1, height: 1 }} />,
};

const navConfig: NavConfig[] = [
  { title: 'Clusters', path: PATH_DASHBOARD.general.clusters, icon: ICONS.clusters },
  {
    title: 'Notifications',
    path: PATH_DASHBOARD.notifications.management,
    icon: ICONS.notifications,
    // TODO - Review the RBAC list for this group
    rbac: {
      resource: [authz.model.resources.alerts, authz.model.resources.notifications],
      verb: [authz.model.verbs.get, authz.model.verbs.list],
    },
  },
  {
    title: 'Component Builder',
    path: PATH_DASHBOARD.configurator.root,
    icon: ICONS.componentBuilder,
  },
  {
    title: 'Users',
    path: PATH_DASHBOARD.user.root,
    icon: ICONS.user,
    rbac: {
      resource: authz.model.resources.users,
      verb: [authz.model.verbs.get, authz.model.verbs.list],
    },
  },
  {
    title: 'API Keys',
    path: PATH_DASHBOARD.apiKeys.index,
    icon: ICONS.apiKeys,
    rbac: {
      resource: authz.model.resources.apikeys,
      verb: [...values(authz.model.verbs)],
    },
  },
  {
    title: 'Organization',
    path: PATH_DASHBOARD.organization.root,
    icon: ICONS.organization,
    rbac: {
      resource: authz.model.resources.organizations,
      verb: [authz.model.verbs.get],
    },
  },
];

export default navConfig;
