import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';
import { deserialise } from 'kitsu-core';
import { ErrorResponseType } from '../types';
import { Normalization, normalizeResource } from '../utils';

type HandleGetRequestOptions = {
  jsonApiClient: AxiosInstance;
  url: string;
  params?: Record<string, unknown>;
  axiosOptions?: Partial<AxiosRequestConfig>;
  cancelToken?: CancelToken;
  normalization?: Normalization;
};

// This function handles GET requests and normalizes the response data
export async function handleGetRequest({
  jsonApiClient,
  url,
  params,
  axiosOptions = {},
  cancelToken,
  normalization,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
HandleGetRequestOptions): Promise<QueryReturnValue<{ data: any } | { error: any }, any, any>> {
  try {
    const { data: responseData } = await jsonApiClient.get(url, {
      params,
      ...axiosOptions,
      cancelToken,
    });

    // Normalize the response data if necessary and return unnormalized data if an error occurs
    try {
      return normalizeResource(deserialise(responseData), normalization);
    } catch (error) {
      console.warn('Error normalising resource', error);
      return { data: { ...deserialise(responseData) } };
    }
  } catch (error) {
    const err = error as ErrorResponseType;
    const status = err.response?.status;
    const data = err.response?.data?.errors ?? err.response?.data ?? err.message;
    return {
      error: {
        status,
        data,
      },
    };
  }
}
