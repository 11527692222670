import { differenceInMinutes, formatRFC3339 } from 'date-fns';
import type { DateTimeRangePickerValues } from '../components';
import { GLOBAL_AVAILABLE_TIMESPANS } from '../utils';
import { useLocalStorage } from './useLocalStorage';

const defaultTimespan = GLOBAL_AVAILABLE_TIMESPANS['30m'];
const defaultTimeRange = {
  relativeTime: GLOBAL_AVAILABLE_TIMESPANS['30m'].ts,
};

export function useMetricsTimespan() {
  const [timespan, setTimespan] = useLocalStorage('metricsTimeSpan', defaultTimespan);
  const [timeRange, setTimeRange] = useLocalStorage<DateTimeRangePickerValues>('metricsTimeRange', defaultTimeRange);

  const now = Date.now();
  const sevenDaysAgo = new Date(now - 7 * 24 * 60 * 60 * 1000);

  const handleUpdateTimespan = (value: DateTimeRangePickerValues | null) => {
    const isEmpty = !value || (value && !value.relativeTime && !value.startTime && !value.endTime);
    if (!value || isEmpty) {
      setTimespan(defaultTimespan);
      setTimeRange(defaultTimeRange);
      return;
    }
    setTimeRange(value);
    if (value.relativeTime) {
      setTimespan(GLOBAL_AVAILABLE_TIMESPANS[value.relativeTime]);
    }
    if (value.startTime && value.endTime) {
      setTimespan(generateStepDateRange(value.startTime, value.endTime));
    }
  };
  return {
    timespan,
    timeRange,
    handleUpdateTimespan,
    sevenDaysAgo,
  };
}

const generateStepDateRange = (start: Date, end: Date) => {
  const difMin = differenceInMinutes(new Date(end), new Date(start));
  let step = '30s';

  const oneHour = 60;
  const oneDay = 24 * 60;

  Object.keys(GLOBAL_AVAILABLE_TIMESPANS).forEach((key) => {
    const currentTs = GLOBAL_AVAILABLE_TIMESPANS[key].ts;
    const currentStep = GLOBAL_AVAILABLE_TIMESPANS[key].step;
    const value = parseInt(currentTs ?? '0');
    if (currentTs?.endsWith('m')) {
      if (difMin >= value) {
        step = currentStep;
      }
    }
    if (currentTs?.endsWith('h')) {
      if (difMin / oneHour >= value) {
        step = currentStep;
      }
    }
    if (currentTs?.endsWith('d')) {
      if (difMin / oneDay >= value) {
        step = currentStep;
      }
    }
  });

  return {
    start: formatRFC3339(new Date(start)),
    end: formatRFC3339(new Date(end)),
    step,
  };
};
