import { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Language } from '../types';

type LocalesProps = {
  onChangeDirectionByLang: (lang: string) => void;
  allLangs: Language[];
  defaultLang: Language;
};

export function useSharedTranslations({ onChangeDirectionByLang, allLangs, defaultLang }: LocalesProps) {
  const { i18n, t: translate } = useTranslation();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    onChangeDirectionByLang(newLanguage);
  };

  return {
    onChangeLang: handleChangeLanguage,
    // We need to use ANY in here cause the i18next types do not support the use of variables in the translation for interpolation
    // We technically need to use context prop to pass interpolation vars, but we need to change all translations to use context.interpolatedValue
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    translate: (text: string, options?: TOptions & Record<string, any>) => translate(text, options || {}),
    currentLang,
    allLangs,
  };
}
