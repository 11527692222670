import { useAuth0 } from '@auth0/auth0-react';
import { DiagridUser, UserOrganization } from '@diagrid/cloud-ui-shared';
import { authz } from '@diagrid/shared-js';
import { every, filter, includes, isArray, isEmpty, isEqual, last, map } from 'lodash';
import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { TOUR_SETTINGS } from 'src/components/tours/constants';
import { useFindQuery } from 'src/redux/store';
import { CUSTOM_CLAIMS, DIAGRID_ROLES, DIAGRID_ROLE_DEFINITIONS } from 'src/utils/constants';

type UsersRolesContextStateProps = {
  accountId: string | null;
  customRoles: { id: string; name: string; parsedRole: { role: string; permissions: string[] } }[];
  isGlobalAdmin: boolean;
  organization: Partial<UserOrganization>;
  organizationFeatures: UserOrganization['plan_details']['features'];
  organizationLimits: UserOrganization['plan_details']['limits'];
  roles: { id: string; name: string; parsedRole: { role: string; permissions: string[] } }[];
  standardRoles: { id: string; name: string; parsedRole: { role: string; permissions: string[] } }[];
  diagridAccount: DiagridUser | Partial<DiagridUser & { completedUITours: (typeof TOUR_SETTINGS)[keyof typeof TOUR_SETTINGS][] }>;
  userPermissions: { rules: string[] };
  isLoadingDiagridUser: boolean;
  isFetchingDiagridUser: boolean;
};

const initialState: UsersRolesContextStateProps = {
  accountId: null,
  customRoles: [],
  isGlobalAdmin: false,
  organization: {
    blocked: undefined,
    blockedTimestamp: undefined,
  },
  organizationFeatures: [],
  organizationLimits: {},
  roles: [],
  standardRoles: [],
  diagridAccount: {
    // Defaulting this to the dismissed state so we don't get the onboarding modal on every page load
    // upon first load the default will get replaced by the actual user data
    completedUITours: [TOUR_SETTINGS.DISMISS],
  },
  userPermissions: {
    rules: [],
  },
  isLoadingDiagridUser: false,
  isFetchingDiagridUser: false,
};

const UsersRolesContext = createContext({
  ...initialState,
  cleanup: () => null,
  hasRoles: (_): boolean => false,
  hasFeature: (_): boolean => false,
  getLimit: (_): number => 0,
  isAuthorized: (_): boolean => false,
  setAccountId: (_): void => null,
  refetchDiagridAccount: (_?): void => null,
});

const renderSafeArray = [];

function UserRolesProvider({ children }: PropsWithChildren<unknown>) {
  const { user, isAuthenticated, isLoading: isAuthenticating } = useAuth0();
  const [state, setState] = useState({ ...initialState });
  const [skip, setSkip] = useState(isEmpty(user?.sub));

  const {
    data: diagridAccount,
    refetch: refetchDiagridAccount,
    isLoading: isLoadingDiagridUser,
    isFetching: isFetchingDiagridUser,
  } = useFindQuery(
    { type: 'userSelf', normalization: { skip: true } },
    {
      skip:
        skip ||
        isAuthenticating ||
        !isAuthenticated ||
        !user?.[CUSTOM_CLAIMS.rolesLoaded] ||
        isEmpty(user[CUSTOM_CLAIMS.defaultOrganization]),
    }
  );

  const currentUser = useMemo(() => diagridAccount?.data ?? initialState.diagridAccount, [diagridAccount?.data]);

  const userRoles = useMemo(
    () =>
      currentUser?.roles
        ? map(currentUser?.roles, (r) => {
            const parsedRole = authz.model.parseRoleString(r);
            return { id: r, name: last(parsedRole.role.split(':')), parsedRole };
          })
        : renderSafeArray,
    [currentUser?.roles]
  );

  const standardRoles = useMemo(() => filter(userRoles, ({ id }) => includes(DIAGRID_ROLE_DEFINITIONS, id)), [userRoles]);
  const customRoles = useMemo(() => filter(userRoles, ({ id }) => !includes(DIAGRID_ROLE_DEFINITIONS, id)), [userRoles]);

  const userPermissions = useMemo(() => {
    if (isEmpty(currentUser?.roles)) {
      return { rules: [] };
    }
    try {
      const perms = authz.model.buildPermissions(currentUser?.roles);
      return perms;
    } catch (error) {
      console.error(error);
      return { rules: [] };
    }
  }, [currentUser?.roles]);

  const organizationFeatures = useMemo(
    () => currentUser.organization?.plan_details?.features ?? [],
    [currentUser?.organization?.plan_details?.features]
  );

  const organizationLimits = useMemo(
    () => currentUser?.organization?.plan_details?.limits ?? {},
    [currentUser?.organization?.plan_details?.limits]
  );

  const organization = useMemo(() => currentUser?.organization ?? {}, [currentUser?.organization]);

  useEffect(() => {
    if (!isEqual(state.diagridAccount, currentUser)) {
      setState((prevState) => ({ ...prevState, diagridAccount: currentUser }));
    }
  }, [currentUser, state.diagridAccount]);

  useEffect(() => {
    if (state.accountId !== user?.sub) {
      setState((prevState) => ({ ...prevState, accountId: user?.sub }));
      setSkip(isEmpty(user?.sub));
    }
  }, [state.accountId, user?.sub]);

  useEffect(() => {
    if (!isEmpty(userRoles)) {
      const isGlobalAdmin = userRoles.some((r) => r.id === DIAGRID_ROLES.admin.id); // global admin
      setState((prevState) => ({ ...prevState, roles: userRoles, standardRoles, customRoles, userPermissions, isGlobalAdmin }));
    }
    if (!isEmpty(organizationFeatures)) {
      setState((prevState) => ({ ...prevState, organizationFeatures }));
    }
    if (!isEmpty(organizationLimits)) {
      setState((prevState) => ({ ...prevState, organizationLimits }));
    }
    if (!isEmpty(organization)) {
      setState((prevState) => ({ ...prevState, organization }));
    }
  }, [customRoles, organization, organizationFeatures, organizationLimits, standardRoles, userPermissions, userRoles]);

  const cleanup = () => {
    setState(initialState);
  };

  const setAccountId = (accountId) => {
    setState((prevState) => ({ ...prevState, accountId }));
    setSkip(isEmpty(accountId));
  };

  const memoizedIsAuthorized = useMemo(
    () => (operation) => {
      if (operation?.debug) {
        console.info('memoizedIsAuthorized', operation, userPermissions);
      }

      if (organization?.blocked && !operation?.skipOrgCheck) {
        return false;
      }

      try {
        if (isArray(operation?.resource)) {
          return every(operation.resource, (resource) => {
            const op = { ...operation, resource };

            if (isArray(op.verb)) {
              return every(op.verb, (verb) => authz.authorizer.isAuthorized(userPermissions, { ...op, verb }));
            }

            return authz.authorizer.isAuthorized(userPermissions, op);
          });
        }

        if (isArray(operation?.verb)) {
          return every(operation.verb, (verb) => authz.authorizer.isAuthorized(userPermissions, { ...operation, verb }));
        }

        return authz.authorizer.isAuthorized(userPermissions, operation);
      } catch (error) {
        console.error('isAuthorized[UI] error', error);
        return false;
      }
    },
    [organization?.blocked, userPermissions]
  );

  // memo this so the callback updates when userRoles changes
  const memoizedHasRoles = useMemo(
    () =>
      (roles = []) =>
        roles.some((role) => userRoles.some((r) => r.parsedRole.role === role)),
    [userRoles]
  );

  function hasFeature(features, feat) {
    return includes(features, feat);
  }

  // memo this so the callback updates when userRoles changes
  const memoizedHasFeature = useMemo(
    () =>
      (feature): boolean =>
        hasFeature(organizationFeatures, feature),
    [organizationFeatures]
  );

  function getLimit(limits, li: string | string[]): number {
    if (isArray(li)) {
      return li.reduce((acc, l) => acc + (limits[l] ?? 0), 0);
    }
    return limits[li] ?? 0;
  }

  // memo this so the callback updates when userRoles changes
  const memoizedGetLimit = useMemo(
    () =>
      (limitName: string | string[]): number =>
        getLimit(organizationLimits, limitName),
    [organizationLimits]
  );

  return (
    <UsersRolesContext.Provider
      value={{
        ...state,
        cleanup,
        setAccountId,
        refetchDiagridAccount,
        isLoadingDiagridUser,
        isFetchingDiagridUser,
        hasRoles: memoizedHasRoles,
        hasFeature: memoizedHasFeature,
        getLimit: memoizedGetLimit,
        isAuthorized: memoizedIsAuthorized,
      }}
    >
      {children}
    </UsersRolesContext.Provider>
  );
}

export { UserRolesProvider, UsersRolesContext };
