import { Box, Container, Paper, Stack, Typography, styled } from '@mui/material';
import { Page } from 'src/components/Page';
import { useLocales } from 'src/hooks/useLocales';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  margin: 'auto',
  minHeight: '20vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2, 0),
}));
export function SignUpSuccess() {
  const { translate } = useLocales();
  return (
    <Page title={translate('pages.signupSuccess.pageTitle')}>
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
            <Stack direction="row" sx={{ mb: 5, alignItems: 'center', textAlign: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h3" paragraph>
                  {translate('pages.signupSuccess.successTitle')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{translate('pages.signupSuccess.successDescription')}</Typography>
              </Box>
            </Stack>
          </Paper>
        </ContentStyle>
      </Container>
    </Page>
  );
}
