import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_METHODS } from '../../utils/globals';
import { handleDelete } from './deleteHandler';
import { handleGetRequest } from './getHandler';
import { handlepatchPatchPutPost } from './patchPutPostHandler';

type BaseQueryOptions = { baseUrl?: string; jsonApiClient: AxiosInstance };
type BaseQueryFnOptions = {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  resourceType?: string;
  resourceId?: string | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  normalization?: any;
};

export function baseQuery({ baseUrl = '/', jsonApiClient }: BaseQueryOptions): BaseQueryFn<BaseQueryFnOptions, unknown, unknown> {
  return async function baseQueryFn({
    url,
    resourceType,
    resourceId,
    method,
    data,
    normalization,
  }): Promise<QueryReturnValue<unknown, unknown, object>> {
    // always ensure we're going to the correct endpoint
    const axiosOptions: Partial<AxiosRequestConfig> = { baseURL: baseUrl }; // marked as partial because we' may want to add to it
    // TODO: Currently we use generateApiParams at the useQuery/Destroy/Update level to generate the params but we should consider moving that here to ensure all query methods are consistent
    const params = { ...(data?.params ?? {}) };

    if (data?.baseUrl) {
      // this allows us to reset the base url for following links
      axiosOptions.baseURL = data?.baseUrl;
    }

    try {
      switch (method) {
        default:
        case API_METHODS.GET: {
          return handleGetRequest({ jsonApiClient, url, params, axiosOptions, normalization });
        }
        case API_METHODS.DELETE: {
          return handleDelete({
            jsonApiClient,
            url,
            params,
            axiosOptions,
            resourceId: resourceId as string,
            resourceType: resourceType as string,
          });
        }
        case API_METHODS.PATCH:
        case API_METHODS.PUT:
        case API_METHODS.POST: {
          return handlepatchPatchPutPost({
            jsonApiClient,
            url,
            params,
            axiosOptions,
            resourceType: resourceType as string,
            resourceId: resourceId as string,
            data,
            method: method as 'PATCH' | 'PUT' | 'POST',
          });
        }
      }
    } catch (e) {
      console.error('There was an error processing the request in baseQuery: ', e);
      // return error object instead of throwing, required by "useAPIResponseHandler"
      return { error: e };
    }
  };
}
