/* eslint-disable @typescript-eslint/no-explicit-any */
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosInstance } from 'axios';
import { deserialise } from 'kitsu-core';
import { compact, flatMapDeep, keys, merge, omit, toPairs, uniq } from 'lodash';
import { API_METHODS } from '../../utils';
import { baseQuery } from '../baseQuery';
import { normalizeResource } from '../utils';

type UnknownQueryResponse = {
  [key: string]: any;
};
type UnknownQueryArgs = {
  [key: string]: any;
};

type ApiStoreOptions = {
  basePath: string;
  jsonApiClient: AxiosInstance;
  typeReference: Record<string, { url: string; tagType: string }>;
  reducerPath?: string;
};

function urlFromType(typeReference, type, args) {
  const { url } = typeReference[type] ?? {};

  if (!args) {
    return url;
  }

  return toPairs(args).reduce((newUrl, [key, value]) => {
    return newUrl.replace(`:${key}`, encodeURIComponent(value as string));
  }, url);
}

export function initializeApiStore({ basePath, jsonApiClient, typeReference, reducerPath = 'diagrid.io/v1beta1' }: ApiStoreOptions) {
  return createApi({
    refetchOnFocus: true,
    reducerPath: reducerPath,
    tagTypes: [...compact(uniq(flatMapDeep(typeReference, 'tagType'))), 'versions'],
    baseQuery: baseQuery({
      baseUrl: basePath,
      jsonApiClient,
    }),
    endpoints: (builder) => ({
      find: builder.query<UnknownQueryResponse, UnknownQueryArgs>({
        query({ type, resourceType, id, data, normalization, additionalUrlArgs, urlOverride }) {
          const url = urlOverride ?? urlFromType(typeReference, type, id ? { id, ...additionalUrlArgs } : { ...additionalUrlArgs });

          return {
            url,
            resourceType: resourceType ?? type,
            resourceId: id,
            method: API_METHODS.GET,
            data,
            normalization,
          };
        },
        providesTags: (result, error, { type, id }) =>
          id
            ? [{ type: typeReference[type]?.tagType ?? type, id }]
            : [
                ...keys(result).map((id) => ({ type: typeReference[type]?.tagType ?? type, id })),
                { type: typeReference[type]?.tagType ?? type, id: 'LIST' },
              ],
      }),
      destroy: builder.mutation({
        query({ type, resourceType, id, normalization, data, additionalUrlArgs, urlOverride }) {
          const url = urlOverride ?? urlFromType(typeReference, type, { ...additionalUrlArgs });

          return {
            url,
            resourceType: resourceType ?? type,
            resourceId: id,
            method: API_METHODS.DELETE,
            data,
            normalization,
          };
        },
        invalidatesTags: (result, error, { type, id }) => [
          { type: typeReference[type]?.tagType ?? type, id },
          { type: typeReference[type]?.tagType ?? type, id: 'LIST' },
        ],
      }),
      destroyMany: builder.mutation({
        queryFn: async function (
          { type, resourceType, ids, data, normalization, additionalUrlArgs },
          _queryApi,
          _extraOptions,
          baseQueryFn
        ): Promise<QueryReturnValue<{ data: any } | { error: any }, object, object>> {
          const allPromises = ids.map((id) => {
            const baseQueryArgs = {
              url: urlFromType(typeReference, type, { ...additionalUrlArgs }),
              resourceType: resourceType ?? type,
              resourceId: id,
              method: API_METHODS.DELETE,
              data,
              normalization,
            };
            return baseQueryFn(baseQueryArgs) as Promise<QueryReturnValue<{ data: any } | { error: any }, object, object>>;
          });

          try {
            const response = await Promise.all(allPromises);

            const out = { data: {} };

            response.forEach((resp) => {
              merge(out, normalizeResource(deserialise(resp)));
            });

            return out as QueryReturnValue<{ data: any }, object, object>;
          } catch (error) {
            return error as QueryReturnValue<{ error: any }, object, object>;
          }
        },
        invalidatesTags: (result, error, { type, id }) => [
          { type: typeReference[type]?.tagType ?? type, id },
          { type: typeReference[type]?.tagType ?? type, id: 'LIST' },
        ],
      }),
      // ...
      create: builder.mutation({
        query({ type, resourceType, data, normalization, additionalUrlArgs }) {
          const url = urlFromType(typeReference, type, { ...additionalUrlArgs });

          return {
            url,
            resourceType: resourceType ?? type,
            resourceId: undefined,
            method: API_METHODS.POST,
            data,
            normalization,
          };
        },
        invalidatesTags: (result, error, { type, id }) => [
          { type: typeReference[type]?.tagType ?? type, id },
          { type: typeReference[type]?.tagType ?? type, id: 'LIST' },
        ],
      }),
      update: builder.mutation({
        query({ type, resourceType, id, data, normalization, additionalUrlArgs, urlOverride, method = API_METHODS.PUT }) {
          const url = urlOverride ?? urlFromType(typeReference, type, { id, ...additionalUrlArgs });

          return {
            url,
            resourceType: resourceType ?? type,
            resourceId: id,
            method,
            data,
            normalization,
          };
        },
        invalidatesTags: (result, error, { type, id }) => [
          { type: typeReference[type]?.tagType ?? type, id },
          { type: typeReference[type]?.tagType ?? type, id: 'LIST' },
        ],
      }),
      patch: builder.mutation({
        query({ type, resourceType, id, data, normalization, additionalUrlArgs, urlOverride }) {
          const url = urlOverride ?? urlFromType(typeReference, type, { id, ...additionalUrlArgs });

          return {
            url,
            resourceType: resourceType ?? type,
            resourceId: id,
            method: API_METHODS.PATCH,
            data,
            normalization,
          };
        },
        invalidatesTags: (result, error, { type, id }) => [
          { type: typeReference[type]?.tagType ?? type, id },
          { type: typeReference[type]?.tagType ?? type, id: 'LIST' },
        ],
      }),
      getVersions: builder.query({
        query(data = true) {
          const url = data ? '/diagrid.io/v1beta1/versions?data=true' : '/diagrid.io/v1beta1/versions';
          return { url, method: 'GET', resourceType: undefined, resourceId: undefined, data: undefined, normalization: undefined };
        },
        transformResponse: (response) => {
          const neu = omit(response as any, 'headers');
          return neu;
        },
        providesTags: ['versions'],
      }),
    }),
  });
}
