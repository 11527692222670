import { CodeBlock, MotionContainer } from '@diagrid/cloud-ui-shared';
import { varBounce } from '@diagrid/cloud-ui-shared/components/animate/variants';
import { Box, Container, Link, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { Trans } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { Page } from '../components/Page';
import { useLocales } from '../hooks/useLocales';
import { LINKS } from '../utils/external-links';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  margin: 'auto',
  paddingTop: theme.spacing(5),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

type GenericErrorProps = {
  error?: Error;
  resetErrorBoundary?: () => void;
};
export default function GenericError({ error, ..._rest }: GenericErrorProps) {
  const theme = useTheme();
  const { translate } = useLocales();
  const [searchParams] = useSearchParams();
  const errorDescription = searchParams.get('error_description');

  return (
    <Page title={translate('pages.genericError.pageTitle')}>
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              {translate('pages.genericError.title')}
            </Typography>
            <Typography variant="h6">
              <Trans
                i18nKey="pages.genericError.description"
                components={{
                  c1: (
                    <RouterLink reloadDocument to="/">
                      homepage
                    </RouterLink>
                  ),
                  c2: <Link href={LINKS.mailToSupport} rel="noopener noreferrer" />,
                }}
              />
            </Typography>
          </m.div>

          {errorDescription && (
            <m.div variants={varBounce().in} style={{ maxWidth: theme.breakpoints.values.md }}>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" paragraph mb={2}>
                  {translate('pages.genericError.technicalDescription')}
                </Typography>
                {errorDescription && <CodeBlock value={errorDescription} language="shellSession (shell-session)" />}
                {error?.stack && <CodeBlock value={error.stack} language="shellSession (shell-session)" />}
              </Box>
            </m.div>
          )}
        </ContentStyle>
      </Container>
    </Page>
  );
}
