import { Box, Container, LinearProgress, Link, Paper, Stack, Typography, styled } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Page } from 'src/components/Page';
import { HOST_API } from 'src/config';
import { useLocales } from 'src/hooks/useLocales';
import { PATH_API } from 'src/routes/paths';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  margin: 'auto',
  paddingTop: theme.spacing(5),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const baseURL = HOST_API ?? '/'; // localhost

// This axiosClient is used to send the token to the backend
// This is different from the jsonApiClient in setup in axios.ts initialization
// The reason is that jsonApiClient uses getAccessTokenSilently() to get the token from auth0
// but when a user email is not verified the token returned from getAccessTokenSilently() is not valid as its missing the org id claims and the org is not setup until the user verifies their email
// the PATH_API.resendVerification endpoint is setup to accept the token without the org id claims, but all other auth'd endpoints require the org id claims
const axiosClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/vnd.api+json',
  },
});

export function VerifyEmail() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    function initializeInterceptor() {
      axiosClient.interceptors.request.use(
        (request) => {
          request.headers.Authorization = `Bearer ${token}`;
          return request;
        },
        (error) => error
      );
    }

    if (token) {
      initializeInterceptor();
    }
  }, [token]);

  const handleResendEmail = async () => {
    setIsLoading(true);
    try {
      await axiosClient.post(PATH_API.resendVerification);
      enqueueSnackbar(translate('notifications.success.verificationEmailSent'), {
        variant: 'success',
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(translate('notifications.errors.verificationEmailSent'), {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page title={translate('pages.verifyEmail.pageTitle')}>
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Paper elevation={3} sx={{ mt: 3 }}>
            <LinearProgress sx={{ visibility: isLoading ? 'visible' : 'hidden' }} />
            <Stack direction="row" sx={{ p: 3, mb: 5, alignItems: 'center', textAlign: 'center' }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h3" paragraph>
                  {translate('pages.verifyEmail.title')}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  <Trans
                    i18nKey="pages.verifyEmail.description"
                    components={[
                      <Link
                        key="verify-email-translation-link"
                        onClick={handleResendEmail}
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                      >
                        Resend Verification Email
                      </Link>,
                    ]}
                  />
                </Typography>
              </Box>
            </Stack>
          </Paper>
        </ContentStyle>
      </Container>
    </Page>
  );
}
